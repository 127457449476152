import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { ProductContextProvider } from "./components/contexts/ProductContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import "./i18n";

//https://stackoverflow.com/a/44864001/828184
import "moment/locale/es";
import "moment/locale/de";

ReactDOM.render(
	<React.StrictMode>
		<ProductContextProvider>
			<App />
		</ProductContextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
