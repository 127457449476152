const { EventEmitter } = require("events");

//https://medium.com/trabe/synchronize-cache-updates-in-node-js-with-a-mutex-d5b395457138
export const useLock = ({ maxListeners }) => {
	let locked = {};
	const ee = new EventEmitter();
	ee.setMaxListeners(maxListeners);

	return {
		acquire: (key) =>
			new Promise((resolve) => {
				if (!locked[key]) {
					locked[key] = true;
					return resolve();
				}

				const tryAcquire = (value) => {
					if (!locked[key]) {
						locked[key] = true;
						ee.removeListener(key, tryAcquire);
						return resolve(value);
					}
				};

				ee.on(key, tryAcquire);
			}),

		// If we pass a value, on release this value
		// will be propagated to all the code that's waiting for
		// the lock to release
		release: (key, value) => {
			Reflect.deleteProperty(locked, key);
			setTimeout(() => ee.emit(key, value), 30);
		},
	};
};
