import React from "react";
import { useNicerContextProvider } from "./hooks/useNicerContextProvider";
import { useNicerContextStore } from "./hooks/useNicerContextStore";

export const ProductContext = React.createContext(null);

export const ProductContextProvider = ({ children }) => {
	const storageName = "productStore";

	const { loadObjectFromStore, persist } = useNicerContextStore({
		storageName,
	});

	const { contextState } = useNicerContextProvider({
		loadObjectFromStore,
		persist,
	});

	return (
		<ProductContext.Provider value={contextState}>
			{children}
		</ProductContext.Provider>
	);
};
