import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ProductContext } from "./contexts/ProductContext";
import "../assets/scss/CallToActionButton.scss";

const CallToActionButton = ({ size = "sm" }) => {
	switch (size) {
		case "sm":
		case "lg":
			break;
		default:
			throw new Error(`Unknown size ${size}`);
	}

	const { t } = useTranslation();
	const { contextCallToActionLink } = useContext(ProductContext);

	return (
		<span className='CallToActionButton'>
			<div className={`action-button ${size}`}>
				<Button
					onClick={() => {
						window.location.href = contextCallToActionLink;
					}}
					variant='outline-dark'
					size={size}
				>
					{t("callToAction")}
				</Button>
			</div>
		</span>
	);
};
export default CallToActionButton;
