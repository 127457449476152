import React, { useContext, useEffect } from "react";
import CarouselSection from "./CarouselSection";
import ComparisionSection from "./ComparisionSection";
import Footer from "./Footer";
import Header from "./Header";
import ServicesSections from "./ServicesSections";
import axios from "axios";
import { ProductContext } from "./contexts/ProductContext";
import CallToActionSection from "./CallToActionSection";
import { useTranslation } from "react-i18next";

const Home = () => {
	const { i18n } = useTranslation();
	const { setContextStateAndPersistMultiple } = useContext(ProductContext);

	useEffect(() => {
		const constructor = async () => {
			const response = await axios("/config.json");
			const config = response.data;
			const {
				productViewImg,
				logoImg,
				comparisonObject,
				callToActionLink,
				contactLink,
				service1Img,
				service2Img,
				service3Img,
				productName,
			} = config;
			const { product1Name, product2Name, columnInfo } = comparisonObject;

			await setContextStateAndPersistMultiple({
				arrayOfKeyValue: [
					{
						key: "contextProductViewImg", //available as context
						value: productViewImg,
					},
					{
						key: "contextLogoImg", //available as context
						value: logoImg,
					},
					{
						key: "contextProduct1Name",
						value: product1Name,
					},
					{
						key: "contextProduct2Name",
						value: product2Name,
					},
					{
						key: "contextColumnInfo",
						value: columnInfo,
					},
					{
						key: "contextCallToActionLink",
						value: callToActionLink,
					},
					{
						key: "contextContactLink",
						value: contactLink,
					},
					{
						key: "contextService1Img",
						value: service1Img,
					},
					{
						key: "contextService2Img",
						value: service2Img,
					},
					{
						key: "contextService3Img",
						value: service3Img,
					},
					{
						key: "contextProductName",
						value: productName,
					},
				],
			});
		};

		constructor();
	}, [i18n.language]);

	return (
		<>
			<Header />

			<CarouselSection />
			<ServicesSections />
			<ComparisionSection />
			<CallToActionSection />
			<Footer />
		</>
	);
};

export default Home;
