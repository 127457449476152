import React from "react";
import { Trans, useTranslation } from "react-i18next";
import CallToActionButton from "./CallToActionButton";

const ServicesSection = ({
	imgSrc,
	titleTranslationKey,
	descriptionTranslationKey,
}) => {
	const { t } = useTranslation();
	return (
		<div className='serviceCard text-center'>
			<img className='servicesImg' src={imgSrc} alt='' />
			<h2 className='serviceName'>{t(titleTranslationKey)}</h2>
			<p className='serviceDes'>
				<Trans i18nKey={descriptionTranslationKey} />
			</p>

			<CallToActionButton />
		</div>
	);
};

export default ServicesSection;
