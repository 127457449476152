import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useLanguageDetection from "./hooks/useLanguageDetection";

const LanguageSwitch = () => {
	const { i18n } = useTranslation();
	const { detectCurrentLanguage } = useLanguageDetection();
	const location = useLocation();

	const [langButtons, setLangButtons] = useState([]);

	useEffect(() => {
		const langFromLocalStorage = localStorage.getItem("lang");

		const initialLangButtons = [
			{
				label: "DE",
				value: "de",
				selected: detectCurrentLanguage().startsWith("de"),
				onClick: () => changeLanguage({ lng: "de" }),
			},
			{
				label: "EN",
				value: "en",
				selected: detectCurrentLanguage().startsWith("en"),
				onClick: () => changeLanguage({ lng: "en" }),
			},
		];

		if (langFromLocalStorage) {
			changeLanguage({
				lng: langFromLocalStorage,
				saveToLocalStorage: false,
			});
		}

		setLangButtons(initialLangButtons);
	}, [i18n.language]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const langFromUrl = queryParams.get("lang");

		if (langFromUrl) {
			changeLanguage({ lng: langFromUrl });
		}
	}, [location.search]);

	const changeLanguage = ({ lng, saveToLocalStorage = true }) => {
		if (i18n.language !== lng) {
			i18n.changeLanguage(lng);
			if (saveToLocalStorage) {
				localStorage.setItem("lang", lng);
			}
		}
	};

	return (
		<span className='langSwitch button-style'>
			{langButtons.map((item, index) => {
				const { value, onClick, label } = item;

				return (
					<button
						disabled={i18n.language.startsWith(value)}
						onClick={onClick}
						key={index}
					>
						{label}
					</button>
				);
			})}
		</span>
	);
};

export default LanguageSwitch;
