import { useLock } from "./useLock";

export const useNicerContextStore = ({ storageName }) => {
	const { acquire, release } = useLock({ maxListeners: 0 });

	const saveObjectToStoreWithoutLock = async (obj) => {
		const stringified = JSON.stringify(obj);
		localStorage.setItem(storageName, stringified);
	};

	const loadObjectFromStore = async () => {
		await acquire(storageName);

		let deserialized;
		let stringified;
		try {
			stringified = localStorage.getItem(storageName) ?? "{}";
			deserialized = JSON.parse(stringified);
		} finally {
			release(storageName);
		}

		return deserialized;
	};

	const loadObjectFromStoreWithoutLock = async () => {
		const stringified = localStorage.getItem(storageName) ?? "{}";
		const deserialized = JSON.parse(stringified);

		return deserialized;
	};

	const persist = async (partialObj) => {
		await acquire(storageName);

		try {
			const loadedStore = await loadObjectFromStoreWithoutLock();
			const modifiedStore = { ...loadedStore, ...partialObj };
			await saveObjectToStoreWithoutLock(modifiedStore);
		} finally {
			release(storageName);
		}
	};

	return { loadObjectFromStore: loadObjectFromStore, persist: persist };
};
