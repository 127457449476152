import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../assets/scss/ModalPicture.scss";

const ModalPicture = (props) => {
	const { t } = useTranslation();
	const {
		titleTranslationKey,
		subTitleTranslationKey,
		descriptionTranslationKey,
		imgSrc,
	} = props;

	return (
		<Modal
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<span className='ModalPicture'>
				<Modal.Header closeButton>
					<Modal.Title id='contained-modal-title-vcenter'>
						{t(titleTranslationKey)}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{subTitleTranslationKey && (
						<h4>{t(subTitleTranslationKey)}</h4>
					)}
					{descriptionTranslationKey && (
						<p>{t(descriptionTranslationKey)}</p>
					)}
					<img src={imgSrc} className='modal-image' />
				</Modal.Body>
			</span>
		</Modal>
	);
};

export default ModalPicture;
