import "../assets/scss/CallToActionSection.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Trans } from "react-i18next";
import CallToActionButton from "./CallToActionButton";

const CallToActionSection = () => {
	return (
		<div className='CallToActionSection' id='CallToActionSection'>
			<Container>
				<Row>
					<Col md={8} sm={12} className='CTA-C-Text'>
						<h2 className='CTA-S-Title'>
							<Trans i18nKey='callToActionTitle' />
						</h2>
						<p className='CTA-S-Text'>
							<Trans i18nKey='callToActionText' />
						</p>
					</Col>

					<Col md={4} sm={12} className='CTA-C-Button'>
						<CallToActionButton size='lg' />
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default CallToActionSection;
