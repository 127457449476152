import { useEffect, useState } from "react";

export const useNicerContextProvider = ({ loadObjectFromStore, persist }) => {
	const setContextStateAndPersist = async ({ key, value }) => {
		let newContextState = { ...contextState };
		newContextState[key] = value;
		await persist(newContextState);
		setContextState(newContextState);
	};

	const setContextStateAndPersistMultiple = async ({
		arrayOfKeyValue,
		beforePersist,
		afterPersist,
	}) => {
		let newContextState = { ...contextState };

		if (!Array.isArray(arrayOfKeyValue)) {
			throw new Error(
				"setContextStateAndPersistMultiple: arrayOfKeyValue must be an array"
			);
		}

		for (let i = 0; i < arrayOfKeyValue.length; i++) {
			const currentKeyValue = arrayOfKeyValue[i];

			if (!currentKeyValue.key.startsWith("context")) {
				throw new Error(
					`naming violation: useNicerContextProvider key needs to start with "convention"  for key ${currentKeyValue.key}.`
				);
			}

			newContextState[currentKeyValue.key] = currentKeyValue.value;
		}

		if (beforePersist) {
			await beforePersist();
		}

		await persist(newContextState);

		if (afterPersist) {
			await afterPersist();
		}

		setContextState(newContextState);
	};

	const [contextState, setContextState] = useState({
		setContextStateAndPersistMultiple: setContextStateAndPersistMultiple,
	});

	const load = async () => {
		const storedState = await loadObjectFromStore();
		const alteredState = {
			...contextState,
			...storedState,
			storageReady: true,
		};
		setContextState(alteredState);
	};

	useEffect(() => {
		if (!contextState.storageReady) {
			load();
		}
	}, [contextState.storageReady]);

	return {
		contextState: contextState,
	};
};
