import React from "react";
import { useTranslation } from "react-i18next";

const useLanguageDetection = (defaultLanguage) => {
	if (!defaultLanguage) {
		defaultLanguage = "en";
	}

	const { i18n } = useTranslation();

	const detectCurrentLanguage = () => {
		return i18n.language.startsWith("dev")
			? defaultLanguage
			: i18n.language.toLowerCase().substr(0, 2);
	};

	return { detectCurrentLanguage };
};

export default useLanguageDetection;
