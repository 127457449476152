import { Trans, useTranslation } from "react-i18next";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Col, Container, Row } from "react-bootstrap";

const CarouselSection = () => {
	const { t } = useTranslation();

	const carouselData = {
		items: [
			{
				img: "/images/hitachi-abb-logo.png",
				compNameTranslationKey: "carouselCompanyNameSlider2",
				compMessageTranslationKey: "carouselMessageSlider2",
			},
			{
				img: "/images/CodingYourLifeLogo_TShirts.png",
				compNameTranslationKey: "carouselCompanyNameSlider1",
				compMessageTranslationKey: "carouselMessageSlider1",
			},
			// {
			// 	img: "/images/gpn-logo-web.png",
			// 	compNameTranslationKey: "carouselCompanyNameSlider3",
			// 	compMessageTranslationKey: "carouselMessageSlider3",
			// },
		],
	};

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};
	return (
		<Carousel
			swipeable={true}
			draggable={true}
			showDots={false}
			responsive={responsive}
			infinite={true}
			autoPlay={true}
			autoPlaySpeed={5000}
			keyBoardControl={true}
			customTransition='all 5'
			transitionDuration={500}
			containerClass='carousel-container'
			// removeArrowOnDeviceType={["tablet", "mobile"]}
			dotListClass='custom-dot-list-style'
			itemClass='carousel-item-padding-40-px'
		>
			{carouselData.items.map((item, index) => {
				const {
					img,
					compNameTranslationKey,
					compMessageTranslationKey,
				} = item;

				const height = "300px";

				return (
					<div key={index}>
						<Container>
							<Row
								className='align-items-center'
								style={{ height: height }}
							>
								<Col
									className='align-self-center my-auto'
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "column",
									}}
									md={3}
									sm={12}
								>
									<img
										src={img}
										style={{
											width: "200px",
											maxHeight: "200px",
										}}
									/>
								</Col>
								<Col
									className='align-items-center'
									md={9}
									sm={12}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										flexDirection: "column",
									}}
								>
									<div>
										<h3>{t(compNameTranslationKey)}</h3>
										<p>{t(compMessageTranslationKey)}</p>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				);
			})}
		</Carousel>
	);
};

export default CarouselSection;
