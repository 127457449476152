import React, { Suspense } from "react";

import "./App.scss";
import "./locales/i18n";

import { BrowserRouter } from "react-router-dom";
import Switch from "react-bootstrap/esm/Switch";
import { Routers } from "./components/Routers";
import Home from "./components/Home";

function Page() {
	return (
		<React.StrictMode>
			<Suspense fallback={<div>Loading...</div>}>
				<BrowserRouter>
					<Switch>
						<Routers
							routers={[
								{
									path: "/",
									component: Home,
								},
							]}
						/>
					</Switch>
				</BrowserRouter>
			</Suspense>
		</React.StrictMode>
	);
}

// loading component for suspense fallback
const Loader = () => (
	<div className='App'>
		<div>Loading...</div>
	</div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
	return (
		<Suspense fallback={<Loader />}>
			<Page />
		</Suspense>
	);
}
