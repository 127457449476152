import React, { useContext } from "react";
import "../assets/scss/Footer.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";
import LanguageSwitch from "./LanguageSwitch";
import { useTranslation } from "react-i18next";
import { ProductContext } from "./contexts/ProductContext";

const Footer = () => {
	const { t } = useTranslation();
	const { contextContactLink } = useContext(ProductContext);

	return (
		<div>
			<footer className='Footer py-2'>
				<div className='container Footer-container'>
					<Row>
						<Col md={4} className='f-col-1'>
							<a
								href='https://codingyourlife.at/'
								target='_blank'
								rel='noopener norefferer'
							>
								<img
									src={
										"/images/CodingYourLifeLogo_TShirts.png"
									}
									alt='CYL-LOGO'
								/>
							</a>
							<p className='slogan'>
								Developing software that
								<br />
								makes <span>YOUR</span> life easier
							</p>
						</Col>

						<Col md={4}>
							<div className='contact'>
								<span>CodingYourLife e.U</span>
							</div>
							<Row>
								<Col>
									<div className='socials'>
										<a
											href='https://www.facebook.com/CodingYourLife/'
											className='faf'
											target='_blank'
											rel='noopener norefferer'
										>
											<FaFacebook />
										</a>
										<a
											href='https://www.youtube.com/user/CodingYourLife'
											className='fay'
											target='_blank'
											rel='noopener norefferer'
										>
											<FaYoutube />
										</a>
									</div>
								</Col>
							</Row>
						</Col>

						<Col md={4} className='f-col-2'>
							<div>
								<h3 className='title text-center'>
									{t("links")}
								</h3>
								<ul className='list'>
									<li>
										<a
											href={contextContactLink}
											target='_blank'
											rel='noopener norefferer'
										>
											{t("contact")}
										</a>
									</li>
									&nbsp; | &nbsp;
									<li>
										<a
											href='https://codingyourlife.at/dsgvo.htm'
											target='_blank'
											rel='noopener norefferer'
										>
											{t("gdpr")}
										</a>
									</li>
								</ul>
							</div>
						</Col>
					</Row>

					<div className='copyright-lang'>
						<Row>
							<Col md={6} className='Col-copyright'>
								<p className='copyright'>
									Copyright <span>&copy;</span> 2020 All
									Rights Reserved
								</p>
							</Col>

							<Col md={6}>
								<div className='lang-Button'>
									<FaGlobe /> &nbsp;
									<LanguageSwitch />
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
