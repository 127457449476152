import "../assets/scss/Header.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Trans, useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { ProductContext } from "./contexts/ProductContext";
import CallToActionButton from "./CallToActionButton";
import ModalPicture from "./ModalPicture";

const Header = () => {
	const { t } = useTranslation();
	const [modalShow, setModalShow] = useState(false);

	const {
		contextProductViewImg,
		contextLogoImg,
		contextProductName,
	} = useContext(ProductContext);

	return (
		<div id='header'>
			<ModalPicture
				// titleTranslationKey={"product-modal-title"}
				// subTitleTranslationKey={"product-modal-subtitle"}
				// descriptionTranslationKey={"product-modal-description"}
				imgSrc={contextProductViewImg}
				show={modalShow}
				onHide={() => setModalShow(false)}
			/>
			<header>
				<Container>
					<Row className='product-section'>
						<Col md={6} sm={12} className='product-logo'>
							<img src={contextLogoImg} alt='logo' />
							<h1>{contextProductName}</h1>

							<p className='productDescription'>
								{/* <Trans i18nKey='productShortDescription' /> */}
								<Trans i18nKey='productShortDescription' />
							</p>
							<div className='text-left'>
								<CallToActionButton size='lg' />
							</div>
						</Col>

						<Col md={6} sm={12} className='product-pic'>
							<img
								src={contextProductViewImg}
								alt='logo'
								onClick={() => setModalShow(true)}
							/>
						</Col>
					</Row>
				</Container>
			</header>
		</div>
	);
};

export default Header;
