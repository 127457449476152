import "../assets/scss/ServicesSection.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import ServicesSection from "./ServicesSection";
import { useContext } from "react";
import { ProductContext } from "./contexts/ProductContext";

const ServicesSections = () => {
	const { t } = useTranslation();
	const {
		contextService1Img,
		contextService2Img,
		contextService3Img,
	} = useContext(ProductContext);

	return (
		<section>
			<Container className='Services-section text-center'>
				<h1 className='title text-center p-5'>{t("servicesTitle")}</h1>
				<Row>
					<Col lg={4} md={6} sm={12}>
						<ServicesSection
							imgSrc={contextService1Img}
							titleTranslationKey='serviceTitle1'
							descriptionTranslationKey='serviceDescription1'
						/>
					</Col>

					<Col lg={4} md={6} sm={12}>
						<ServicesSection
							imgSrc={contextService2Img}
							titleTranslationKey='serviceTitle2'
							descriptionTranslationKey='serviceDescription2'
						/>
					</Col>

					<Col lg={4} md={6} sm={12}>
						<ServicesSection
							imgSrc={contextService3Img}
							titleTranslationKey='serviceTitle3'
							descriptionTranslationKey='serviceDescription3'
						/>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ServicesSections;
