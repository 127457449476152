import { useContext } from "react";
import { Trans } from "react-i18next";
import "../assets/scss/ComparisionSection.scss";
import { ProductContext } from "./contexts/ProductContext";

const ComparisionSection = () => {
	const { contextProduct1Name, contextProduct2Name, contextColumnInfo } =
		useContext(ProductContext);

	return (
		<section>
			<article className='mt-5'>
				<h2 className='title text-center p-5'>
					<Trans i18nKey='comparison' />
				</h2>

				<table>
					<thead>
						<tr>
							<td colSpan='1' className='sep'></td>
							<th className='text-center'>
								{contextProduct1Name}
							</th>
							<th className='text-center'>
								{contextProduct2Name}
							</th>
						</tr>
					</thead>

					<tbody>
						{contextColumnInfo &&
							contextColumnInfo.map(
								(
									{
										comparisonTranslationKey,
										isProduct1Checked,
										isProduct2Checked,
									},
									item
								) => {
									return (
										<tr key={item}>
											<td className='text-center'>
												<Trans
													i18nKey={
														comparisonTranslationKey
													}
												/>
											</td>
											<td>
												{isProduct1Checked === true && (
													<span className='tick'>
														&#10004;
													</span>
												)}
												{isProduct1Checked == false && (
													<span className='cross'>
														&#215;
													</span>
												)}
											</td>

											<td>
												{isProduct2Checked === true && (
													<span className='tick'>
														&#10004;
													</span>
												)}
												{isProduct2Checked == false && (
													<span className='cross'>
														&#215;
													</span>
												)}
											</td>
										</tr>
									);
								}
							)}
					</tbody>
				</table>
			</article>
		</section>
	);
};

export default ComparisionSection;
